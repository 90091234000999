import React, { useEffect, useState } from "react";
import ".././assets/css/Feature.css";
import Feature1 from "../assets/images/feature1.svg";
import Feature2 from "../assets/images/feature2.svg";
import Feature3 from "../assets/images/feature3.svg";
import Feature4 from "../assets/images/feature4.svg";
import Feature5 from "../assets/images/feature5.svg";
import Feature6 from "../assets/images/feature6.svg";
import FeatureCloud from "../assets/images/feature-cloud.svg";
import FeatureLines from "../assets/images/feature-lines.svg";
import ourFeatures from "../assets/images/our-features.svg";
import featureCloudMobile from "../assets/images/feature-cloud-mobile.svg";
import closeFeatureLines from "../assets/images/close-feature-lines.svg";
import resposiveFeatureLines from "../assets/images/mobile-view-feature-connectors.svg";
import allRoles from "../assets/images/all-roles.svg";
import { Helmet } from 'react-helmet';
import 'animate.css';

function Roles() {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScroll(window.pageYOffset);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (scroll >= 1000) {
            document.querySelector(".effortless-content").classList.add("animate__animated", "animate__fadeInDown", "animate__delay-1s");
            document.querySelector(".left-feature").classList.add("animate__animated", "animate__fadeIn", "animate__delay-3s");
            document.querySelector(".right-feature").classList.add("animate__animated", "animate__fadeIn", "animate__delay-4s");
            document.querySelector(".feature-cloud").classList.add("animate__animated", "animate__zoomIn", "animate__delay-2s");
            document.querySelector(".feature-lines").classList.add("animate__animated", "animate__zoomIn", "animate__delay-3s");
            document.querySelector(".feature-box").classList.add("feature-box-animations");
        }
    }, [scroll]);
    return (
        <div className="container-fluid feature-overall-container">
            <Helmet>
                <title>CXOTulz</title>
                <meta property="og:title" content="CXOTulz" />
                <meta name="keywords" content="cost management in azure, azure cloud cost management, microsoft azure cost management, azure cost management aws, management of cloud computing, cloud & infrastructure, cloud management standards, managing the cloud infrastructure" />
                <meta property="og:description" content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity" />
                <meta property="og:url" content="https://www.cxotulz.com/#feature-section" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.cxotulz.com/#feature-section" />
            </Helmet>
            <div className="container feature-box">
                <p className="effortless-content">Your Solution, Your Scale: Ideal for <span className="our-feature-img"><img src={allRoles} className="our-feature-role" alt="Our Feature content" /></span></p>
                <div className="responsiveness-cloud">
                    <img src={FeatureCloud} alt="feature cloud" className="responsive-cloud img-fluid" />
                    <img src={featureCloudMobile} alt="cxo feature cloud" className="feature-cloud-mobile img-fluid" />
                </div>
                <div className="container feature-container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-4 col-5 left-feature">
                            <img src={Feature1} alt="Integration with multiple cloud services for comprehensive data collection." className="feature img-fluid " />
                            <img src={Feature2} alt="User-friendly interface for easy interpretation of data and streamlined decision-making." className="feature img-fluid " />
                            <img src={Feature3} alt="Customizable dashboard with real-time updates for timely decision-making." className="feature img-fluid " />
                        </div>
                        <div className="col-lg-4 col-sm-4 col-2">
                            <img src={FeatureCloud} alt="cxo feature cloud" className="feature-cloud img-fluid" />
                            <img src={FeatureLines} alt="cxo feature lines" className="feature-lines " />
                            <img src={closeFeatureLines} alt="cxo feature lines" className="close-feature-lines " />
                            <img src={resposiveFeatureLines} alt="cxo feature lines" className="responsive-feature-lines" />
                        </div>
                        <div className="col-lg-4 col-sm-4 col-5 right-feature">
                            <img src={Feature4} alt="Scalability to accommodate adaptive data needs and changing business requirements." className="feature img-fluid " />
                            <img src={Feature5} alt="Ensures data security and compliance with industry standards and regulations." className="feature img-fluid " />
                            <img src={Feature6} alt="Ensures data security and compliance with industry standards and regulations." className="feature img-fluid " />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roles;