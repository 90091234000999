import * as Yup from "yup";
import "yup-phone-lite";
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const formSchema = Yup.object({
    fname: Yup.string().min(2).max(25).required("Enter first name"),
    lname: Yup.string().min(2).max(25).required("Enter last name"),
    email: Yup.string().email().required("Enter your email"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("A phone number is required"),
})

export const emailFormSchema = Yup.object({
    email: Yup.string().email().required("Enter your email"),
})