import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/About';
import Roles from './components/Roles';
import ScheduleDemo from './components/ScheduleDemo';
import Contact from './components/Contact';
import Footer from './components/Footer';
import OurFeature from './components/OurFeature';
import { Helmet } from 'react-helmet';

function App() {
    const scrollToSection = (sectionId, offset) => {
      const section = document.getElementById(sectionId);
      if (section) {
        const yOffset = offset || 0; // Default offset to 0
        const sectionHeight = section.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: sectionHeight, behavior: "smooth" });
      }
    };

    return (
        <div className="App">
            <Helmet>
                <title>CXOTulz</title>
                <meta property="og:title" content="CXOTulz" />
                <meta name="keywords" content="cloud cost optimisation, aws cost management, google cloud user management, gcp vulnerability management, aws it infrastructure, feature of cloud services, infrastructure as cloud, cloud infrastructure visualization" />
                <meta property="og:description" content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity" />
                <meta property="og:url" content="https://www.cxotulz.com/" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.cxotulz.com/" />
            </Helmet>
            {/* Header section */}
            <Header scrollToSection={scrollToSection} />
            {/* Hero Section  */}
            <HeroSection scrollToSection={scrollToSection}/>
            {/* About us section */}
            <AboutSection />
            {/* Our Feature section */}
            <OurFeature />
            {/* Roles section */}
            <Roles />
            {/* schedule demo section */}
            <ScheduleDemo />
            {/* contact us section */}
            <Contact />
            {/* footer section */}
            <Footer scrollToSection={scrollToSection} />
        </div>
    );
}

export default App;
