import React, { useEffect, useState } from "react";
import ".././assets/css/Footer.css";
import cxotulzLogo from "../assets/images/cxotulz-logo.svg";
import linkedIn from "../assets/images/linkedIn.svg";
import facebook from "../assets/images/facebook.svg";
import WingmanPartners from "../assets/images/wingman.svg";
import verticalLine from "../assets/images/vertical-line.svg";
import { Helmet } from "react-helmet";
import "animate.css";

function Footer(props) {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScroll(window.pageYOffset);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (scroll >= 2800) {
      document
        .querySelector(".cxotulz-logo")
        .classList.add(
          "animate__animated",
          "animate__zoomIn",
          "animate__delay-1s"
        );
    }
  }, [scroll]);

    const handleClick = (sectionId, offset) => {
      if (props && props.scrollToSection) {
        props.scrollToSection(sectionId, offset);
      }
    };

  return (
    <div className="container-fluid footer-section" id="footerSection">
      <Helmet>
        <title>CXOTulz</title>
        <meta property="og:title" content="CXOTulz" />
        <meta
          name="keywords"
          content="azure resource group cost analysis, azure cost management report, cxoadvisory, incident management aws, gcp vulnerability management, cost optimization in aws, multi cloud management platform, cost optimization on aws, cloud management tool, cloud management tooling"
        />
        <meta
          property="og:description"
          content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity"
        />
        <meta property="og:url" content="https://www.cxotulz.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.cxotulz.com/" />
      </Helmet>
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 streamline-box">
          <div className="streamline-inner-box">
            <a href="#">
              <img
                src={cxotulzLogo}
                alt="CXO Tulz logo"
                className="cxotulz-logo img-fluid"
              />
            </a>
            <p className="powered-by-text">Powered By</p>
            <a href="https://www.wingmanpartners.com/" target="_blank" rel="noreferrer">
              <img
                src={WingmanPartners}
                alt="wingman partners"
                className="img-fluid wmp-logo"
              />
            </a>
          </div>
        </div>

        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 vertical-line">
          <img
            src={verticalLine}
            alt="vertical line"
            className="vertical-line"
          />
        </div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-3 streamline-container">
          <p className="streamline-text">
            Streamline your Cloud Management under Unified Data visualization
          </p>
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-4 menu-section">
          <p className="menu-heading">Menu</p>
          <p onClick={() => handleClick("heroSection", 100)} className="menu-item">Home</p>
          <p onClick={() => handleClick("aboutSection", 100)} className="menu-item">About</p>
          <p onClick={() => handleClick("featureSection", 55)} className="menu-item">Features</p>
          <p onClick={() => handleClick("scheduleDemoSection", 120)} className="menu-item schedule-demo-mobile-view">
            Schedule a demo
          </p>
        </div>

        <div className="col-lg-2 col-md-2 col-sm-2 col-2">
          <div className="social-handle-section">
            <div>
              <p className="menu-heading follow-us-text">Follow Us</p>
            </div>
            <div>
              <span className="linkedin-section">
                <a href="https://www.linkedin.com/company/cxo-tulz/" target="_blank" rel="noreferrer">
                  <img
                    src={linkedIn}
                    alt="LinkedIn Logo"
                    className="linked-logo"
                  />
                </a>
                <a href="https://www.linkedin.com/company/cxo-tulz/" target="_blank" rel="noreferrer"><p className="menu-item linked-in">LinkedIn</p> </a>
              </span>
              <span className="facebook-section">
                <a href="https://www.facebook.com/profile.php?id=61556432252434" target="_blank" rel="noreferrer">
                  <img
                    src={facebook}
                    alt="Facebook Logo"
                    className="facebook-logo"
                  />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61556432252434" target="_blank" rel="noreferrer"><p className="menu-item facebook">Facebook</p></a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-12 mobile-view-streamline-text">
          <p className="streamline-text-mobile d-block d-md-none">
            Streamline your Cloud Management under Unified Data visualization
          </p>
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="copyright-box">
        <p className="copyright-text mb-0">
          © Copyright 2024 by CXOTulz. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;