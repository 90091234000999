import React, { useEffect, useState } from "react";
import ".././assets/css/About.css";
import Actions from "../assets/images/actions.svg";
import Comments from "../assets/images/comments.svg";
import costAnalysis from "../assets/images/cost-analysis.svg";
import monthlyReports from "../assets/images/monthly-reports.svg";
import Statistics from "../assets/images/statistics.svg";
import { Helmet } from 'react-helmet';
import 'animate.css';

function HeroSection() {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScroll(window.pageYOffset);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (scroll >= 450) {
            document.querySelector(".about-right-content").classList.add("animate__animated", "animate__fadeInRight", "right-container-animation-effect", "animate__delay-0s");
            document.querySelector(".statistics").classList.add("animate__animated", "animate__zoomIn", "animate__delay-3s");
            document.querySelector(".comments").classList.add("animate__animated", "animate__zoomIn", "animate__delay-4s");
            document.querySelector(".monthly-reports").classList.add("animate__animated", "animate__zoomIn", "img-fluid", "animate__delay-1s");
            document.querySelector(".actions").classList.add("animate__animated", "animate__zoomIn", "animate__delay-4s");
            document.querySelector(".cost-analysis").classList.add("animate__animated", "animate__zoomIn", "animate__delay-2s");
            document.querySelector(".charts-section").classList.add("chart-section-animation-effect");
        }
    }, [scroll]);

    return (
        <div className="container-fluid about-container" id="aboutSection">
            <Helmet>
                <title>CXOTulz</title>
                <meta property="og:title" content="CXOTulz" />
                <meta name="keywords" content="aws cost explorer, cloud cost management, cloud management platform, cloud cost dashboard, cloud management, api management cost azure, cloud asset management" />
                <meta property="og:description" content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity" />
                <meta property="og:url" content="https://www.cxotulz.com/#about-section" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.cxotulz.com/#about-section" />
            </Helmet>
            <div className="row about-section-bg">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 about-left-section order-sm-2 order-2 order-lg-2 order-xl-1">
                    <div className="charts-section">
                        <img src={Statistics} className="statistics img-fluid" alt="statistics" />
                        <img src={Comments} className="comments img-fluid" alt="comments" />
                        <img src={monthlyReports} className="monthly-reports" alt="monthly reports" />
                        <img src={Actions} className="actions img-fluid" alt="Actions" />
                        <img src={costAnalysis} className="cost-analysis img-fluid" alt="cost analysis" />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 about-right-section order-sm-1 order-1 order-lg-1 order-xl-2">
                    <div className="about-right-content">
                        <p className="actionable-content">Actionable data insights in one place.</p>
                        <p className="cxoTulz-content">Our platform goes beyond conventional cloud management, offering users a comprehensive view that fosters quick and effective decision-making, propelling your business towards accelerated growth. Through intuitive graphical representations, it transforms complex data into easily digestible visuals, providing a dynamic dashboard that empowers users to stay ahead in the digital landscape. Experience a new dimension of control and strategic advantage with CXOTulz, where actionable insights converge seamlessly, revolutionizing the way you navigate and optimize your cloud infrastructure.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;