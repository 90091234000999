import React, { useEffect, useState } from "react";
import ".././assets/css/Contact.css";
import leftCloud from "../assets/images/left-cloud.svg";
import rightCloud from "../assets/images/right-cloud.svg";
import { useFormik } from "formik";
import { emailFormSchema } from "../schemas";
import { Helmet } from 'react-helmet';
import 'animate.css';

function Contact() {
    const initialValues = {
        email: "",
    }
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScroll(window.pageYOffset);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (scroll >= 2500) {
            document.querySelector(".left-cloud-container").classList.add("animate__animated", "animate__slideInLeft", "animate__delay-1s");
            document.querySelector(".right-cloud-container").classList.add("animate__animated", "animate__slideInRight", "animate__delay-1s");
            document.querySelector(".contact-details").classList.add("animate__animated", "animate__slideInDown", "animate__delay-1s");
            document.querySelector(".contact-animation").classList.add("contact-section-visible");
        }
    }, [scroll]);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: emailFormSchema,
        onSubmit: (values, { resetForm }) => {
            fetch('https://49ojpdiu4a.execute-api.us-east-2.amazonaws.com/v1/handlingSESMail', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({
                    "email": values.email,
                    "formId": "cxo-email-form",
                })
            })
            resetForm();
        },
    });

    return (
        <div className="container-fluid contact-us-box contact-animation" id="contactSection">
            <Helmet>
                <title>CXOTulz</title>
                <meta property="og:title" content="CXOTulz" />
                <meta name="keywords" content="aws cloudwatch dashboard pricing, amazon cloud management, google cloud executives, gcp cost monitoring, cloud costs, aws cost optimization" />
                <meta property="og:description" content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity" />
                <meta property="og:url" content="https://www.cxotulz.com/#contact-section" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.cxotulz.com/#contact-section" />
            </Helmet>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 text-center left-cloud-container d-flex justify-content-start align-items-center">
                    <img src={leftCloud} alt="left Cloud" className="left-cloud text-center" />
                </div>
                <div className="contact-details col-lg-6 col-md-6 col-sm-6 mobile-view-contact">
                    <p className="we-love-text">We'd love to hear from you! Get in touch with us today.</p>
                    <form className="" onSubmit={handleSubmit}>
                        <span className="contact-box">
                            <div className="contact-div">
                                <input className="contact-input mobile-contact-view" type="email" name="email" autoComplete="off" placeholder="Enter your email*" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                {errors.email && touched.email ? (<p className="email-error-message">{"Please enter a valid email"}</p>) : null}
                            </div>
                            <div className="contact-div-btn">
                                <button className="contact-btn mobile-contact-button" type="submit" >Contact Us</button>
                            </div>
                        </span>
                    </form>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 text-center right-cloud-container d-flex justify-content-end align-items-center pr-0 contact-bottom-cloud">
                    <img src={rightCloud} alt="right Cloud" className="right-cloud" />
                </div>
            </div>
        </div>
    )
}

export default Contact;