import React, { useState, useMemo, useEffect } from "react";
import ".././assets/css/ScheduleDemo.css";
import Chart from "../assets/images/chart.svg";
import CXODashboard from "../assets/images/cxo-dashboard.svg";
import Dashboard from "../assets/images/dashboard.svg";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useFormik } from "formik";
import { formSchema } from "../schemas";
import cloudPlatform from "../assets/images/cloud-platforms.svg";
import { Helmet } from 'react-helmet';
import 'animate.css';

function ScheduleDemo() {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScroll(window.pageYOffset);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (scroll >= 2000) {
            document.querySelector(".cxo-dashboard").classList.add("animate__animated", "animate__slideInRight", "animate__delay-1s");
            document.querySelector(".cxo-charts").classList.add("animate__animated", "animate__zoomIn", "animate__delay-3s");
            document.querySelector(".dashboard").classList.add("animate__animated", "animate__slideInRight", "animate__delay-2s");
            document.querySelector(".form-container").classList.add("animate__animated", "animate__fadeInUp", "animate__delay-4s");
            document.querySelector(".unlock-text").classList.add("animate__animated", "animate__fadeIn", "animate__delay-5s");
            document.querySelector(".container-animation").classList.add("schedule-visible");
        }
    }, [scroll]);

    const initialValues = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        country: "",
    }

    const [country, setCountry] = useState('');
    const options = useMemo(() => countryList().getData(), []);
    const changeHandler = value => {
        setCountry(value);
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: formSchema,
        onSubmit: (values, { resetForm }) => {
            fetch('https://49ojpdiu4a.execute-api.us-east-2.amazonaws.com/v1/handlingSESMail', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({
                    "fname": values.fname,
                    "lname": values.lname,
                    "email": values.email,
                    "phone": values.phone,
                    "country": country.label,
                    "formId": "cxo-schedule-form"
                })
            })
            setCountry('');
            resetForm();
        },
    });

    return (
        <div className="container-fluid demo-container container-animation" id="scheduleDemoSection">
            <Helmet>
                <title>CXOTulz</title>
                <meta property="og:title" content="CXOTulz" />
                <meta name="keywords" content="google cloud secretmanager, platform and infrastructure, data platform infrastructure, platform in cloud computing, managed cloud platform, cloud facilities" />
                <meta property="og:description" content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity" />
                <meta property="og:url" content="https://www.cxotulz.com/#scheduleDemo-section" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.cxotulz.com/#scheduleDemo-section" />
            </Helmet>
            <div className="schedule-background">
                <p className="unlock-responsive-content">Unlock the true Visibility in your <span><img src={cloudPlatform} alt="cloud platform" className="cloud-platform" /></span> like never before.</p>
                <div className="row">
                    <div className="col-lg-6 col-md-12 outside-form-container">
                        <div className="form-container">
                            <p className="schedule-demo">Schedule a CXOTulz Demo</p>
                            <form className="form-box" onSubmit={handleSubmit}>
                                <span className="name-input-box">
                                    <div>
                                        <input className="input-box name-box" type="name" name="fname" autoComplete="off" placeholder="First Name*" value={values.fname} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.fname && touched.fname ? (<p className="error-message">{errors.fname}</p>) : null}
                                    </div>
                                    <div>
                                        <input className="input-box name-box" type="name" name="lname" autoComplete="off" placeholder="Last Name*" value={values.lname} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.lname && touched.lname ? (<p className="error-message">{errors.lname}</p>) : null}
                                    </div>
                                </span>
                                <div>
                                    <input className="input-box one-linear-input-box" type="email" name="email" autoComplete="off" placeholder="Enter your email*" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.email && touched.email ? (<p className="error-message">{errors.email}</p>) : null}
                                </div>
                                <div>
                                    <input className="input-box one-linear-input-box" type="tel" name="phone" autoComplete="off" placeholder="Enter your phone number*" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.phone && touched.phone ? (<p className="error-message">{errors.phone}</p>) : null}
                                </div>
                                <div>
                                    <Select options={options} className="one-linear-input-box country-select" name="country" autoComplete="off" value={country} onChange={changeHandler} placeholder="Enter your country*" />
                                    {(touched.country && country == '') ? (<p className="error-message">Please select country</p>) : null}
                                </div>
                                <button type="submit" className="get-start-btn">Get Started</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 chart-sections">
                        <div className="chart-section-inner">
                            <img src={CXODashboard} alt="cxo dashboard" className="cxo-dashboard img-fluid" />
                            <img src={Chart} alt="cxo charts" className="cxo-charts img-fluid" />
                            <img src={Dashboard} alt="cxo dashboard" className="dashboard img-fluid" />
                        </div>
                        <div>
                                <p className="unlock-text">Unlock the true Visibility in your <span><img src={cloudPlatform} alt="cloud platform" className="cloud-platform" /></span> like never before.</p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleDemo;