import React, { useState, useEffect, useRef } from "react";
import cxoTulzLogo from "../assets/images/cxoTulz-logo.svg";
import hamburgerMenu from "../assets/images/hamburger-menu.svg";
import navbarHorizontal from "../assets/images/navbar-horizontal.svg";
import navbarCross from "../assets/images/navbar-cross-icon.svg";
import ".././assets/css/Header.css";
import ".././assets/css/media.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function Header(props) {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [navbarCrossIcon, setNavbarCrossIcon] = useState(false);
  const navigationRef = useRef(null);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (!showMediaIcons) {
      setNavbarCrossIcon(showMediaIcons);
    }
  }, [showMediaIcons]);

  useEffect(() => {
    const items = document.querySelectorAll("ul li");
    // Function to handle click outside navigation
    const handleClickOutside = (event) => {
      if (
        navigationRef.current &&
        !navigationRef.current.contains(event.target)
      ) {
        // Click occurred outside navigation, close the navigation
        setShowMediaIcons(false);
      }
    };
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    items.forEach((item, index) => {
      item.addEventListener("click", () => {
        setActiveIndex(index);
      });
    });
    return () => {
      items.forEach((item, index) => {
        item.removeEventListener("click", () => {
          setActiveIndex(index);
        });
      });
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  window.addEventListener("scroll", function () {
    var navbar = document.getElementById("navbar");
    if (window.scrollY > 0) {
      navbar.classList.add("solid-navbar");
      navbar.classList.remove("transparent-navbar");
    } else {
      navbar.classList.remove("solid-navbar");
      navbar.classList.add("transparent-navbar");
    }
  });

  const handleClick = (sectionId, offset) => {
    if(sectionId == 'scheduleDemoSection') {
      if (props && props.scrollToSection) {
        props.scrollToSection(sectionId, offset);
      }
      // Close the hamburger menu
      setShowMediaIcons(false);
    } else {
      setNavbarCrossIcon(!navbarCrossIcon);
      if (props && props.scrollToSection) {
        props.scrollToSection(sectionId, offset);
      }
      // Close the hamburger menu
      setShowMediaIcons(false);
    }
  };

  return (
    <>
      <div
        data-aos="fade-down"
        className="main-nav highlighted-header"
        id="navbar"
        ref={navigationRef}
      >
        <Helmet>
          <title>CXOTulz</title>
          <meta property="og:title" content="CXOTulz" />
          <meta
            name="keywords"
            content="google cloud executives, aws cloudwatch dashboard pricing, aws content management, gcp user management, cloud cost optimization strategies, cloud cost optimization company, cloud management company, cloud governance tool"
          />
          <meta
            property="og:description"
            content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity"
          />
          <meta property="og:url" content="https://www.cxotulz.com/" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://www.cxotulz.com/" />
        </Helmet>
        {/* Navbar logo section start from here*/}
        <div className="logoSection">
          <a href="#">
            <img className="cxo-logo" src={cxoTulzLogo} alt="cxoTulz Logo" />
          </a>
        </div>
        {/* Menu bar section start from here */}
        <div className={showMediaIcons ? "mobile-menu-link" : "menu-link"}>
          <ul>
            <li
              onClick={() => handleClick("heroSection", 100)}
              className={activeIndex === 0 ? "nav-list active" : ""}
            >
              <a className="nav-text">Home</a>
            </li>
            {showMediaIcons ? (
              <div className="nav-horizontal-line">
                <img src={navbarHorizontal} className="nav-horizontal" />
              </div>
            ) : null}
            <li
              onClick={() => handleClick("aboutSection", 100)}
              className={activeIndex === 1 ? "nav-list active" : ""}
            >
              <a className="nav-text">About</a>
            </li>
            {showMediaIcons ? (
              <div className="nav-horizontal-line">
                <img src={navbarHorizontal} className="nav-horizontal" />
              </div>
            ) : null}
            <li
              onClick={() => handleClick("featureSection", 55)}
              className={activeIndex === 2 ? "nav-list active" : ""}
            >
              <a className="nav-text">Features</a>
            </li>
            {showMediaIcons ? (
              <div className="nav-horizontal-line">
                <img src={navbarHorizontal} className="nav-horizontal" />
              </div>
            ) : null}
            <li
              onClick={() => handleClick("contactSection", 100)}
              className={activeIndex === 3 ? "nav-list active" : ""}
            >
              <a className="nav-text">Contact Us</a>
            </li>
          </ul>
        </div>
        {/* Navbar Button section start from here */}
        <div className="schedule-demo-btn">
          <p onClick={() => handleClick("scheduleDemoSection", 120)}>
            <button className="schedule-btn">Schedule a demo</button>
          </p>
        </div>
        {/* hamburger menu start  */}
        <div className="hamburger-menu">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setNavbarCrossIcon(!navbarCrossIcon);
              setShowMediaIcons(!showMediaIcons);
            }}
          >
            {!navbarCrossIcon ? (
              <img
                src={hamburgerMenu}
                className="hamburger-menu-icon"
                alt="hamburger-menu"
              />
            ) : null}
            {navbarCrossIcon ? (
              <img
                src={navbarCross}
                className="hamburger-menu-icon"
                alt="hamburger-menu"
              />
            ) : null}
          </a>
        </div>
      </div>
    </>
  );
}

export default Header;
