import React, { useEffect, useState } from "react";
import ".././assets/css/OurFeature.css";
import ourFeatures from "../assets/images/our-features.svg";
import costAnalysis from "../assets/images/cost-analysis-optimization.svg";
import realTime from "../assets/images/real-time-insight.svg";
import singleLogin from "../assets/images/single-login.svg";
import dataSecurity from "../assets/images/data-security.svg";
import daas from "../assets/images/daas.svg";
import variedOrganization from "../assets/images/varied-organization.svg";
import agileScaling from "../assets/images/agile-scaling.svg";
import userFriendly from "../assets/images/user-friendly.svg";
import integrateMultiple from "../assets/images/integrate-multiple-clouds.svg"
import { Helmet } from "react-helmet";
import "animate.css";

function OurFeature() {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScroll(window.pageYOffset);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (scroll >= 1000) {
      document
        .querySelector(".effortless-content")
        .classList.add(
          "animate__animated",
          "animate__fadeInDown",
          "animate__delay-1s"
        );
      document
        .querySelector(".left-feature")
        .classList.add(
          "animate__animated",
          "animate__fadeIn",
          "animate__delay-3s"
        );
      document
        .querySelector(".right-feature")
        .classList.add(
          "animate__animated",
          "animate__fadeIn",
          "animate__delay-4s"
        );
      document
        .querySelector(".feature-cloud")
        .classList.add(
          "animate__animated",
          "animate__zoomIn",
          "animate__delay-2s"
        );
      document
        .querySelector(".feature-lines")
        .classList.add(
          "animate__animated",
          "animate__zoomIn",
          "animate__delay-3s"
        );
      document
        .querySelector(".feature-box")
        .classList.add("feature-box-animations");
    }
  }, [scroll]);
  return (
    <div
      className="container-fluid"
      id="featureSection"
    >
      <Helmet>
        <title>CXOTulz</title>
        <meta property="og:title" content="CXOTulz" />
        <meta
          name="keywords"
          content="cost optimization analysis, amazon cost analysis, cloud services, cloud platform, cloud data management, cloud infrastructure management, cloud platform management, system cloud, multicloud storage, infrastructure cloud services"
        />
        <meta
          property="og:description"
          content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity"
        />
        <meta
          property="og:url"
          content="https://www.cxotulz.com/#feature-section"
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.cxotulz.com/#feature-section" />
      </Helmet>
      <div className="container">
        <p
          className="effortless-content"
        >
          Effortlessly manage Your Cloud Infrastructure with{" "}
          <span className="our-feature-img">
            <img
              src={ourFeatures}
              className="our-feature-width"
              alt="Our Feature content"
            />
          </span>
        </p>
        <div className="container">
        <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
          <img src={singleLogin} alt ='single-login' className="img-fluid mb-4"/>
            <p className="feature-subheading">Single Login for All Cloud Accounts:</p>
            <p className="feature-description">Convenience of a single login for all your cloud accounts, streamlining your authentication processes.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section ">
          <img src={costAnalysis} alt ='cost-analysis' className="img-fluid mb-4"/>
            <p className="feature-subheading">Cost Analysis and Optimization:</p>
            <p className="feature-description">Active Intelligence enhances budgeting predicts cloud expenses, suggests instant cost reductions.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
          <img src={realTime} alt ='real-time' className="img-fluid mb-4"/>
            <p className="feature-subheading">Real-Time Insight, Custom Dashboards:</p>
            <p className="feature-description">Customizable dashboard with real-time updates for timely decision making.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
            <img src={integrateMultiple} alt ='integrate-multiple' className="img-fluid mb-4"/>
            <p className="feature-subheading">Integrating Multiple Clouds:</p>
            <p className="feature-description">Integration with multiple cloud services for comprehensive data collection.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
          <img src={dataSecurity} alt ='data-security' className="img-fluid mb-4"/>
            <p className="feature-subheading">Data Security and Compliance:</p>
            <p className="feature-description">Ensure data security and compliance with industry standards and regulations.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
          <img src={daas} alt ='devOps' className="img-fluid mb-4"/>
            <p className="feature-subheading">DaaS (DevOps as a Service):</p>
            <p className="feature-description">Continuous assistance from on demand Tech Team eliminating the need for full time DevOps resources.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
          <img src={variedOrganization} alt ='org-logo' className="img-fluid mb-4"/>
            <p className="feature-subheading">Varied Organization Size and Roles:</p>
            <p className="feature-description">Multiple roles depending upon organizations size and scale.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
          <img src={agileScaling} alt ='agile-scaling' className="img-fluid mb-4"/>
            <p className="feature-subheading">Agile Scaling:</p>
            <p className="feature-description">Scalability to accommodate adaptive data needs and changing business requirements.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 feature-content-section">
            <img src={userFriendly} alt ='user-logo'className="img-fluid mb-4"/>
            <p className="feature-subheading">User-friendly Interface:</p>
            <p className="feature-description">User-friendly interface for easy interpretation of data amd streamlined decision-making.</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default OurFeature;
