import React, { useEffect } from "react";
import ".././assets/css/HeroSection.css";
import ".././assets/css/media.css";
import serviceTree from '../assets/images/service-tree.svg';
import graphCharts from '../assets/images/graphs.svg';
import clouds from '../assets/images/clouds.svg';
import 'animate.css';
import $ from 'jquery';
import { Helmet } from 'react-helmet';

function HeroSection(props) {

    useEffect(() => {
        setTimeout(() => {
            $(".cloud-mgt").addClass("cloud-management");
            $(".cloud-circle").addClass("cloud-mgt-circle");
            $(".under-unified").addClass("under-unified-text");
            $(".effortlessly-content").addClass("effortlessly-content-animation");
        }, 2000);
    }, []);

    const handleClick = (sectionId, offset) => {
        if (props && props.scrollToSection) {
          props.scrollToSection(sectionId, offset);
        }
      };

    return (
        <div className="container-fluid hero-container" id="heroSection">
            <Helmet>
                <title>CXOTulz</title>
                <meta property="og:title" content="CXOTulz" />
                <meta name="keywords" content="google identity and access management, gcp identity management, google cloud platform identity management, gcp cost control, aws spend management, cloud management strategies, cloud cost attribution, optimize the cost" />
                <meta property="og:description" content="Empowering CXO's with CXOTulz. The one-stop solution for collecting, analyzing and presenting cloud costing & security data from all major providers with ease and clarity" />
                <meta property="og:url" content="https://www.cxotulz.com/" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.cxotulz.com/" />
            </Helmet>
            <div className="row hero-container-top">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 inner-hero-section animate__animated animate__fadeInLeft animate__delay-1s">
                    <p className="hero-content">Streamline your</p>
                    <p className="cloud-mgt cloud-mgt-text">Cloud Management</p>
                    <div className="cloud-circle">
                    </div>
                    <p className="hero-content under-unified">under Unified Data&nbsp;Visualization.</p>
                    <p className="effortlessly-content">We understand how challenging data analytics across various platforms can be.</p>
                    <p className="effortlessly-content-1">We are redefining the standard of Cloud Management and Data analytics, making it more than just a function—it's an experience. Imagine effortlessly managing and optimizing your Cloud Infrastructure and data with precision, all while contributing to your organization's growth in unprecedented ways.</p>                    
                    <div className="schedule-button-section"><a onClick={() => handleClick("scheduleDemoSection", 120)} className="anchor-cursor" href="#scheduleDemo-section"><button className="hero-schedule-btn">Schedule a demo</button></a></div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                    <div>
                        <img className="service-tree img-fluid animate__animated animate__zoomIn animate__delay-3s" src={serviceTree} alt="Services tree" />
                        <img className="graph-charts img-fluid animate__animated animate__zoomIn animate__delay-4s" src={graphCharts} alt="Graph Charts" />
                        <img className="banner-clouds img-fluid animate__animated animate__zoomIn animate__delay-3s" src={clouds} alt="Top banner clouds" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;